import React, { useState } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';

import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import {
    LoginWrapper,
    Heading2,
    Heading3,
    TextInput,
    LoginForm,
    Button,
    ContentWrapper,
    LoadingWrapper,
    StyledLink
} from '../components/styled';

const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (email.length === 0) {
            setError('Please provide a valid email address');
            return;
        }

        setLoading(true);

        fetch('https://api.takkt.io/auth/v1/login', {
            method: 'POST',
            body: JSON.stringify({ email: email.toLowerCase() }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (!res.ok) throw new Error('Invalid email, please try again.');

                setLoading(false);
                setSuccess(true);
            })
            .catch((e) => {
                setLoading(false);
                setError(e.message || 'An unexpected error occurred, please try again.');
            });
    };

    return (
        <Layout>
            <Navbar limited />

            <LoginWrapper>
                <ContentWrapper>
                    <div>
                        <Heading2 productName>Log in to Takkt</Heading2>
                        <p>
                            Just enter your email and you’ll receive a magic link that’ll sign you
                            in instantly.
                        </p>
                    </div>

                    <LoginForm onSubmit={onSubmit}>
                        <Heading3>Welcome back</Heading3>

                        {loading ? (
                            <LoadingWrapper className='loading'>
                                <FadeLoader
                                    height={15}
                                    width={5}
                                    radius={2}
                                    margin={2}
                                    color='white'
                                />
                            </LoadingWrapper>
                        ) : success ? (
                            <span>Check your email ✉️</span>
                        ) : (
                            <>
                                <div className='login-input-wrapper'>
                                    <TextInput
                                        type='email'
                                        id='email'
                                        name='email'
                                        placeholder='john.doe@mail.com'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                    <Button>Login</Button>

                                    {error && <p>{error}</p>}
                                </div>

                                <p className='no-account'>
                                    Don’t have an account yet?{' '}
                                    <StyledLink href='/#contact'>Contact us here</StyledLink>
                                </p>
                            </>
                        )}
                    </LoginForm>
                </ContentWrapper>

                <div />
            </LoginWrapper>

            <Footer />
        </Layout>
    );
};

export default Login;
